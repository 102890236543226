import { defineStore } from 'pinia'


export const useCartStore = defineStore('cart', {
  state: () => {
    return {
      cart: []
    }
  },
  getters: {
    total () {
      const { applySale } = useUtils()

      const totalAmount = {
        items: 0,
        totalPrice: 0
      }
      return this.cart.reduce((accumulator, currentValue) => ({
        items: accumulator.items + currentValue.amount,
        totalPrice: accumulator.totalPrice + (currentValue.amount * Math.round(applySale(currentValue.selectedProduct)))
      }), totalAmount)
    }
  },
  actions: {
    addToCart({ customFields, ...product  }) {
      if (product.amount === undefined) { product.amount = 1 }
      const productInCart = this.cart.find(item => item._id === product._id && product.selectedProduct.size === item.selectedProduct.size)
      if (productInCart) {
        productInCart.amount = productInCart.amount + product.amount
      } else {
        this.cart.push({
          customFields: customFields?.map((input) => ({
            label: input.label,
            value: input.value
          })) || [],
          ...product
        })
      }
    },
    removeFromCart(product) {
      const index = this.cart.findIndex(item => item._id === product._id && product.selectedProduct.size === item.selectedProduct.size)
      this.cart.splice(index, 1)
    },
    clear() {
      this.cart = []
    }
  },
  persist: {
    storage: persistedState.localStorage,
  },
})