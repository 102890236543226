import { useStore } from '@/stores/main'

export const useUtils = () => {
  const store = useStore()

  const applySale = ({ price, sale }) => {
    if (store.site.globalSale) {
      return Math.round(price - (price / 100 * store.site.globalSale))
    } else if (sale) {
      return Math.round(price - (price / 100 * sale))
    } else {
      return price
    }
  }
    
  return {
    applySale
  }
}